// import DeliveryHeader from "../../components/Menu/deliveryHeader"
import MenuItem from "../../components/Menu/menuItem"
export default function Menu() {
    return (
        <div>
            {/*<DeliveryHeader />*/}
            <MenuItem/>
        </div>

    )
}